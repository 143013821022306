<script>
  export default {
    props: ["employee", "openVisorByReaction"],
    components: {
      AvatarMonthEmployee: () => import("./AvatarMonthEmployee.vue"),
      Likes: () => import("../reactions/likes/Likes.vue"),
      Views: () => import("../reactions/views/Views.vue"),
      Claps: () => import("../reactions/claps/Claps.vue"),
    },
    computed: {
      isComingSoon() {
        return !this.employee.user;
      },
      nameShow() {
        const { name, lastname } = this.employee.user;
        return `${name} ${lastname}`;
      },
      showCard() {
        return this.employee?.viewers && this.employee.viewers.some(({ user }) => user._id === this.$user._id);
      },
      dataReactions() {
        return {
          item: this.employee._id,
          component: "monthemployees",
        };
      },
      screenWidth() {
        return this.$store.state.screen.width >= 768;
      },
    },
    methods: {
      toogleCard() {
        if (!this.isComingSoon) {
          this.$emit("openModal", this.employee);
        }
      },
    },
  };
</script>
<template>
  <div :class="['monthEmployeeCard', !isComingSoon && 'monthEmployeeCard--discover']" @click="toogleCard">
    <template v-if="isComingSoon || !showCard">
      <div class="monthEmployeeCard__unknownUser">
        <iconic class="monthEmployeeCard__userSkeleton" name="unknownUser" />
        <iconic class="monthEmployeeCard__questionMark" name="questionMark" />
      </div>
      <div class="monthEmployeeCard__infoDiscover">
        <h3 class="monthEmployeeCard__month">{{ employee.period }}</h3>
        <h3 class="monthEmployeeCard__month" v-if="isComingSoon">coming soon</h3>
      </div>
    </template>
    <div class="monthEmployeeCard__user" v-else>
      <div class="monthEmployeeCard__info">
        <h3 class="monthEmployeeCard__month month__avatar--768">{{ employee.period }}</h3>
        <AvatarMonthEmployee :employee="employee" />
        <div class="monthEmployeeCard__user--avatar">
          <div class="monthEmployeeCard__month month__avatar">
            <p class="monthEmployeeCard__month">{{ employee.period }}</p>
          </div>
          <p class="monthEmployeeCard__user--name">{{ nameShow }}</p>
          <p class="monthEmployeeCard__user--area">{{ employee?.area?.name }}</p>
        </div>
      </div>
    </div>
    <div class="monthEmployeeCard__likeCard" v-if="!isComingSoon && showCard">
      <Likes :likes="employee.likers" :showLikes="true" :payload="dataReactions" :openLikes="(data) => openVisorByReaction('likes', data)" />
      <Claps :claps="employee.clapers" :showClaps="true" :payload="dataReactions" :openClaps="(data) => openVisorByReaction('claps', data)" />
      <Views :views="employee.viewers" :showViews="true" :openViews="(data) => openVisorByReaction('views', data)" v-if="$user.role === 'superadmin' && screenWidth" />
    </div>
  </div>
</template>

<style lang="scss">
  .monthEmployeeCard {
    @include Flex(column);
    width: 100%;
    border: 1px solid transparent;
    position: relative;
    padding: 5px;
    background: url("../../assets/img/backgroundCard.png") padding-box,
      linear-gradient(
          342.96deg,
          #91693a 1.71%,
          #a07a43 9.36%,
          #c8a65d 24.68%,
          #dfbf6c 32.34%,
          #b48a42 53.12%,
          #bb944c 59.68%,
          #d1b169 69.53%,
          #f3df96 82.66%,
          #f7e59c 84.84%,
          #e6d08c 87.03%,
          #c7aa6e 91.41%,
          #af8d57 95.78%,
          #9d7846 100.16%,
          #936b3c 103.44%,
          #906739 107.82%
        )
        border-box;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0px 1px 6px 0px rgba(114, 114, 114, 0.25);
    &--discover {
      cursor: pointer;
    }
    &__month {
      display: flex;
      gap: 10px;
      font-family: $newFont_bold;
      font-size: 14px;
      text-align: center;
      text-transform: uppercase;
    }
    &__unknownUser {
      position: relative;
    }
    &__userSkeleton {
      color: #f6dfdf;
      font-size: 76px;
    }
    &__questionMark {
      position: absolute;
      top: 28px;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      font-size: 48px;
    }
    &__likeCard {
      @include Flex(row);
      gap: 10px;
      transform: scale(0.8);
      z-index: 10;
    }
    &__user {
      display: flex;
      width: 100%;
      height: 100%;
      &--avatar {
        @include Column(flex-end, flex-start);
      }
      &--name {
        font-family: $newFont_bold;
        font-size: 12px;
        text-transform: uppercase;
      }
      .month__avatar {
        color: #2a2a2a;
        text-align: start;
        font-size: 11px;
        &--768 {
          display: none;
        }
      }
      &--area {
        font-family: $newFont_regular;
        color: #717171;
        font-size: 12px;
        text-transform: capitalize;
      }
    }
    &__info {
      @include Flex(row);
      align-items: inherit;
      gap: 10px;
      width: 100%;
      padding: 0px 5px;
    }
    &__infoDiscover {
      @include Flex(column);
      gap: 10px;
    }
    @media screen and (min-width: 425px) {
      &__user {
        justify-content: center;
        padding-left: inherit;
      }
    }
    @media screen and (min-width: 500px) {
      width: inherit;
    }
    @media screen and (min-width: 768px) {
      &__user {
        justify-content: space-evenly;
      }
    }
    @media screen and (min-width: 1024px) {
      flex-direction: column;
      min-width: 185px;
      min-height: 250px;
      padding: 10px 0px;
      &__user {
        flex-direction: column;
        align-items: center;
        height: inherit;
        .month__avatar {
          display: none;
          &--768 {
            color: #2a2a2a;
            font-family: $newFont_semiBold;
            font-size: 16px;
            display: inline;
          }
        }
        &--avatar {
          margin: inherit;
          @include Column(flex-end, center);
        }
        &--name {
          font-size: 16px;
        }
      }
      &__userSkeleton {
        font-size: 120px;
      }
      &__info {
        @include Flex(column);
      }
    }
    @media screen and (min-width: 1440px) {
      min-width: inherit;
      padding: 0px;
      .month__avatar {
        &--768 {
          margin-bottom: 7px;
        }
      }
    }
  }
</style>
